import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { H1, H2, H3 } from "../components/Header";
import { LayoutBodyFullwidth } from "../components/Layout";
import { device } from "../constants/device";

const UhOhWrapper = styled.div`
  display: flex;
  margin-top: 100px;

  ${up("lg")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 686px;
    margin-top: 0px;
  }
`;

const UhOhPageLeft = styled.div``;

const UhOhPageLeftMainContent = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  margin-left: 60px;

  ${up("lg")} {
    margin-left: 0px;
  }
`;

const UhOhPageRight = styled.div`
  display: none;

  ${up("lg")} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/header-image.png");
  }
`;

const LeftLogo = styled.img`
  width: 350px;
  height: 220px;
  padding-top: 150px;
`;

const SubTextLeft = styled.div`
  margin-top: 0px;
  margin-left: 60px;
  margin-top: 20px;

  ${up("lg")} {
    margin-left: 89px;
  }
`;

const ButtonWrapper = styled.div`
  padding-right: 40px;
  margin-left: 89px;
  margin-top: 20px;

  ${up("lg")} {
    padding-right: 240px;
    margin-left: 89px;
    margin-top: 20px;
  }
`;

const UhOhPageLeftOne = styled.img`
  width: 136px;
  height: 136px;
  align-self: center;
  margin-top: 0px;

  ${up("lg")} {
    width: 246px;
    height: 246px;
    margin-left: 89px;
    margin-top: 80px;
  }
`;

const UhOhPageLeftTwo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 0px;
  margin-top: 0px;

  ${up("lg")} {
    margin-top: 120px;
    padding-left: 0px;
    padding-right: 60px;
  }
`;

const UhOhTextSub = styled.div`
  margin-top: -60px;

  ${up("lg")} {
    margin-top: 0px;
  }
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 60px;
  font-weight: 900;
  text-align: left;
  line-height: 100px;
  letter-spacing: 0px;

  ${up("lg")} {
    font-size: 120px;
  }
`;

const StyledH2 = styled.h2`
  color: #f27442;
  font-size: 40px;
  font-weight: 900;
  text-align: left;
  line-height: 113px;
  letter-spacing: 0px;
  margin-left: -10px;

  ${up("lg")} {
    font-size: 80px;
  }
`;

const StyledH3 = styled.h3`
  color: #304669;
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  line-height: 25px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const Page404 = () => {
  return (
    <LayoutBodyFullwidth>
      <UhOhWrapper>
        <WrapperLeft />
        <WrapperRight />
      </UhOhWrapper>
    </LayoutBodyFullwidth>
  );
};

const WrapperLeft = () => {
  const navigate = useNavigate();
  return (
    <div>
      <UhOhPageLeft>
        <UhOhPageLeftMainContent>
          <UhOhPageLeftOne
            src="/assets/images/sadtear.svg"
            alt="Sad Tear Face"
          />
          <UhOhPageLeftTwo>
            <StyledH1>404</StyledH1>
            <UhOhTextSub>
              <StyledH2>Uh oh!</StyledH2>
            </UhOhTextSub>
          </UhOhPageLeftTwo>
        </UhOhPageLeftMainContent>
        <SubTextLeft>
          <StyledH3>
            The page you're looking for doesn't <br></br>seem to be here
            anymore! Why not <br></br> have a look at some of our listings{" "}
            <br></br>
            instead?
          </StyledH3>
        </SubTextLeft>
        <ButtonWrapper>
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/buy");
            }}
            type="secondary"
            text="BROWSE ALL LISTINGS"
          />
        </ButtonWrapper>
      </UhOhPageLeft>
    </div>
  );
};

const WrapperRight = () => {
  return (
    <div>
      <UhOhPageRight>
        <LeftLogo
          src="/assets/images/bluebgheartlogo.png"
          alt="Belly Button Blue Heart Background"
        />
      </UhOhPageRight>
    </div>
  );
};

export default Page404;
