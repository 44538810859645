import { down, up } from "styled-breakpoints";
import styled from "styled-components";

export const Ad = (props: {
  href: string;
  desktopImg: string;
  mobileImg: string;
  tabletImg: string;
}) => (
  <a href={props.href} style={{ display: "flex", justifyContent: "center" }}>
    <Desktop src={props.desktopImg} />
    <Mobile src={props.mobileImg} />
    <Tablet src={props.tabletImg} />
  </a>
);

const Desktop = styled.img`
  display: none;
  ${up("lg")} {
    display: block;
  }
  width: 1175px;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 60px;
  object-fit: scale-down;
`;
const Mobile = styled.img`
  width: 75vw;
  margin-bottom: 20px;
  margin-top: 20px;
  ${up("sm")} {
    display: none;
  }
`;

const Tablet = styled.img`
  width: 75vw;
  margin-bottom: 20px;
  ${up("lg")} {
    display: none;
  }
  ${down("sm")} {
    display: none;
  }
`;
