import { ListboxButton } from "@reach/listbox";
import "@reach/listbox/styles.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { H4 } from "../components/Header";
import { ALL_LOCATIONS } from "../constants/locations";
import { useAuth } from "../hooks/useAuth";
import { ISignupDetails } from "./AuthProvider";
import { SelectInputClass } from "./SelectInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { up } from "styled-breakpoints";

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fbf0eb;
  width: 330px;
  height: 895px;
  padding-top: 60px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  select {
    width: 508px;
    align-self: center;
  }

  ${up("lg")} {
    width: 732px;
    height: 795px;
  }
`;

const LabelClass = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: center;

  ${up("lg")} {
    margin-left: 110px;
    text-align: left;
  }
`;

const LabelClassLeft = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: left;
  ${up("lg")} {
    margin-right: 80px;
  }
`;

const LabelClassRight = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: right;
  ${up("lg")} {
    margin-right: 240px;
  }
`;

const InputClass = styled.input`
  align-self: center;
  width: 254px;
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  padding-left: 10px;
  border-color: #304669;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: 500;
  }

  ${up("lg")} {
    width: 508px;
    height: 50px;
  }
`;

const CustomListboxButton = styled(ListboxButton)`
  align-self: center;
  width: 508px;
  height: 50px;
  font-size: 14px;
  color: #304669;
  font-weight: 500;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  background-color: #ffffff;
  padding-left: 10px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: bold;
    padding-left: 20px;
  }
`;

const InputClassLeft = styled.input`
  align-self: left;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 254px;
  height: 50px;
  color: #304669;
  padding-left: 10px;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: 500;
  }

  ${up("lg")} {
    margin-left: 85px;
    width: 226px;
    margin-bottom: 0px;
  }
`;

const InputClassRight = styled.input`
  align-self: right;
  margin-right: 0px;
  margin-top: 10px;
  width: 254px;
  height: 50px;
  color: #304669;
  padding-left: 10px;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: 500;
  }

  ${up("lg")} {
    margin-right: 85px;
    width: 226px;
  }
`;

const FormButton = styled.button`
  align-self: center;
  margin-top: 20px;
  width: 155px;
  height: 63px;
  background-color: #304669;
  border-style: solid;
  border-radius: 3px;
  border-color: #304669;
  color: #ffffff;
  :hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #304669;
    color: #304669;
    transition-duration: 500ms;
  }
`;

const PasswordInfo = styled.div`
  text-align: center;
  margin-right: 35px;
  margin-top: 20px;
  margin-bottom: 0px;

  ${up("lg")} {
    margin-bottom: -20px;
    margin-right: 110px;
    text-align: left;
  }
`;

const SideBySideInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  ${up("lg")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const ChangePasswordInputClass = styled.input`
  width: 254px;
  height: 50px;
  color: #304669;
  padding-left: 10px;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  align-self: center;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }

  ${up("lg")} {
    width: 508px;
    height: 50px;
  }
`;

const TCPLText = styled.div`
  margin-top: 40px;
  font-size: 12px;
  color: #304669;
  font-weight: 500;
`;

const ChangePasswordLabelClass = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: center;
  margin-left: 0px;

  ${up("lg")} {
    margin-left: 110px;
    text-align: left;
  }
`;

const PasswordErrors = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const Dropdown = styled.div`
  padding-left: 37px;
  padding-right: 37px;
`;

export const Signup = () => {
  const navigate = useNavigate();
  const { signup } = useAuth();
  const onSubmit = (data: ISignupDetails) =>
    signup(data, () => {
      navigate("/myaccount");
    });

  const formSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    lastName: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    displayName: Yup.string().required("Required"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(8, "Password must be at least 8 characters long"),
    passwordConfirmation: Yup.string()
      .required("Password is mendatory")
      .min(8, "Password must be at least 8 characters long")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  return (
    <div>
      <SignupForm onSubmit={handleSubmit(onSubmit)}>
        <LabelClass>Email address</LabelClass>
        <InputClass
          placeholder="Email address"
          {...register("email", { required: true })}
        />

        <LabelClass>Username</LabelClass>
        <InputClass
          placeholder="Username"
          {...register("displayName", { required: true })}
        />

        <LabelClass>Location</LabelClass>

        <Dropdown>
          <SelectInputClass
            {...register("location", { required: "Select a region" })}
            defaultValue={""}
          >
            <option disabled value="">
              Location
            </option>
            {Object.keys(ALL_LOCATIONS).map((location: any) => (
              <option key={location} value={location}>
                {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
              </option>
            ))}
          </SelectInputClass>
        </Dropdown>
        <SideBySideInputs>
          <div>
            <LabelClassLeft>First name</LabelClassLeft>
            <InputClassLeft
              placeholder="First name"
              {...register("firstName", { required: true })}
            />
          </div>

          <div>
            <LabelClassRight>Last name</LabelClassRight>
            <InputClassRight
              placeholder="Last name"
              {...register("lastName", { required: true })}
            />
          </div>
        </SideBySideInputs>

        <PasswordInfo>
          <H4
            style={{
              color: "#8A0000",
              fontSize: "12px",
              fontWeight: "500",
              fontStyle: "italic",
              textAlign: "right",
              lineHeight: "20px",
            }}
          >
            Your password must be at least 8 characters long.
          </H4>
        </PasswordInfo>

        <ChangePasswordLabelClass>Password</ChangePasswordLabelClass>
        <ChangePasswordInputClass
          type={"password"}
          {...register("password", { required: true })}
          placeholder="Password"
        />
        <PasswordErrors>{errors.password?.message}</PasswordErrors>

        <ChangePasswordLabelClass>
          Type your password again
        </ChangePasswordLabelClass>
        <ChangePasswordInputClass
          type={"password"}
          {...register("passwordConfirmation", { required: true })}
          placeholder="Confirm password"
        />
        <PasswordErrors>{errors.passwordConfirmation?.message}</PasswordErrors>

        <TCPLText>
          By signing up, you agree to our
          <Link
            to={"/TermsConditions"}
            style={{
              fontSize: "12px",
              color: "#304669",
              textDecoration: "underline",
              marginLeft: "4px",
              marginRight: "2px",
            }}
          >
            terms and conditions{" "}
          </Link>
          and
          <Link
            to={"/PrivacyPolicy"}
            style={{
              fontSize: "12px",
              color: "#304669",
              textDecoration: "underline",
              marginLeft: "4px",
              marginRight: "2px",
            }}
          >
            privacy policy{" "}
          </Link>
        </TCPLText>

        <FormButton type="submit">SIGN UP</FormButton>
      </SignupForm>
    </div>
  );
};
