import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { Button } from "./Button";

export const MobileMenuHero = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = ({ keywords }: any) => {
    navigate("/buy?keywords=" + keywords);
  };

  return (
    <Wrapper>
      <form
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <SearchBox
          {...register("keywords", { required: true })}
          placeholder="What do you need?"
        />
        <div style={{ maxWidth: "200px", marginLeft: "8px", flexGrow: "1" }}>
          <Button
            buttonType="button"
            onClick={handleSubmit(onSubmit)}
            type="bluebg"
            text="SEARCH"
          />
        </div>
        <input type="submit" hidden />
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;

  ${up("lg")} {
    display: none;
  }

  img {
    max-width: 100%;
  }
`;

const SearchBox = styled.input`
  display: flex;
  flex-grow: 1;
  height: 55px;
  outline: solid 4px ${COLORS.NAVY_BLUE};
  inset: solid 4px ${COLORS.NAVY_BLUE};
  border-radius: 4px;
`;
