import { useEffect } from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { useListing } from "../hooks/useListing";
import { HomeHeartLogo } from "../screens/Home";
import { ListingTilePreview } from "./ListingTilePreview";
import { SectionHeading } from "./SectionHeading";

export const OtherListings = (props: { ignoreId: string }) => {
  const { getPublicListings, publicListings } = useListing();

  useEffect(() => {
    getPublicListings();
  }, [getPublicListings]);

  const filteredListings = publicListings.filter(
    (listing) => listing.id.uuid !== props.ignoreId
  );
  const limitedListings = filteredListings.slice(0, 4);

  return (
    <Wrapper>
      <SectionHeading
        icon={<HomeHeartLogo src="/assets/images/bellybutton-hearticon.png" />}
        titleColor={COLORS.DARK_ORANGE}
        title="Other listings you might like"
      >
        <></>
      </SectionHeading>
      <ListingArea>
        {limitedListings.map((listing) => (
          <ListingTilePreview key={listing.id.uuid} listing={listing} />
        ))}
      </ListingArea>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 72px;
`;

const ListingArea = styled.div`
  display: grid;
  width: 100%;
  justify-items: center;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;
