import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { LayoutBodyFullwidth } from "../components/Layout";
import { Signup } from "../components/Signup";

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const SignUpLogo = styled.img`
  width: 100px;
  height: 100px;

  ${up("lg")} {
    width: 119px;
    height: 119px;
  }
`;

const SellRegisterButtonWrapper = styled.div`
  width: 40%;

  ${up("lg")} {
    width: 10%;
  }
`;

const SellRegisterWrap = styled.div`
  margin-bottom: 120px;
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 24px;
  font-weight: normal;
  margin-top: 40px;
  width: 80%;
  ${up("lg")} {
    font-size: 30px;
    width: 100%;
  }
`;

const StyledH4 = styled.h4`
  color: #8a0000;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 10px;
`;

// PAGE LAYOUT EXPORT

export const SellRegister = () => {
  return (
    <SellRegisterWrap>
      <SignUpHeroWrap />
      <LayoutBodyFullwidth>
        <Signup />
      </LayoutBodyFullwidth>
    </SellRegisterWrap>
  );
};

const SignUpHeroWrap = () => {
  const navigate = useNavigate();
  const onLoginClicked = () => {
    navigate("/login");
  };
  return (
    <HeroWrapper>
      <SignUpLogo
        src="/assets/images/bellybutton-hearticon.svg"
        alt="Bellybutton Heart Icon"
      />
      <StyledH1>
        Create an account to sell your items. It's<br></br> simple, free, and
        only takes a few minutes.
      </StyledH1>
      <StyledH4>Already have an account?</StyledH4>
      <SellRegisterButtonWrapper>
        <Button
          onClick={onLoginClicked}
          buttonType="button"
          type="secondary"
          text="LOGIN"
        />
      </SellRegisterButtonWrapper>
    </HeroWrapper>
  );
};
