import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { atom } from "recoil";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { AUTH_ERROR_CODE, useAuth } from "../hooks/useAuth";

const LoginForm = styled.form`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background-color: #fbf0eb;
  width: 330px;
  height: 382px;
  padding-top: 40px;
  justify-content: center;
  margin-top: 40px;

  ${up("lg")} {
    width: 650px;
  }
`;

const LoginLabelClass = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #304669;
  font-weight: bold;
  text-align: center;

  ${up("lg")} {
    text-align: left;
    margin-left: 115px;
  }
`;

const ForgotPassword = styled.div`
  text-align: center;
  margin-left: 0px;
  margin-top: 5px;

  ${up("lg")} {
    text-align: left;
    margin-left: 110px;
  }
`;

const LoginInputClass = styled.input`
  align-self: center;
  width: 85%;
  height: 40px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 10px;

  ${up("lg")} {
    width: 65%;
  }
`;

const LoginButton = styled.button`
  font-size: 16px;
  font-weight: bold;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 10px;
  width: 50%;
  height: 15%;
  background-color: #304669;
  border-style: solid;
  border-radius: 3px;
  border-color: #304669;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #304669;
    color: #304669;
    transition-duration: 500ms;
  }

  ${up("lg")} {
    width: 20%;
  }
`;

interface IUserState {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  bio: string;
  publicData: {
    age: number;
  };
  protectedData: {
    phoneNumber: string;
  };
  privateData: {
    discoveredServiceVia: string;
  };
}

export const userState = atom<IUserState | null>({
  key: "userState",
  default: null,
});

{
  /* Login form to log user in using the sharetribe sdk */
}
export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isLoading, error } = useAuth();

  // @ts-ignore
  const from = location.state ? location.state.from : { pathname: "/" };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: { email: string; password: string }) =>
    login(data, () => navigate(from, { replace: true }));

  return (
    <LoginForm onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <BounceLoader />}

      <LoginLabelClass {...watch("email")}>Email address</LoginLabelClass>
      <LoginInputClass
        defaultValue={""}
        {...register("email", { required: true })}
      />
      <LoginLabelClass {...watch("password")}>Password</LoginLabelClass>
      <LoginInputClass
        type={"password"}
        defaultValue={""}
        {...register("password", { required: true })}
      />
      <ForgotPassword>
        <Link
          to={"/forgotpassword"}
          style={{
            fontSize: "12px",
            color: "#8a0000",
            fontStyle: "italic",
            textAlign: "left",
          }}
        >
          Forgot your password?{" "}
        </Link>
      </ForgotPassword>
      {/* Show error */}

      <LoginButton type="submit">LOGIN</LoginButton>
      <ErrorBox>
        {error === AUTH_ERROR_CODE.INVALID_PASSWORD_OR_USER &&
          "Invalid email or password. Please try again."}
      </ErrorBox>
    </LoginForm>
  );
};

const ErrorBox = styled.p`
  color: ${COLORS.BURGUNDY_RED};
`;
