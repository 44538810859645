import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { H1 } from "./Header";

export const ListItemHero = (props: { text: string }) => (
  <>
    <MobileWrapper>
      <H1
        style={{
          color: "white",
          fontSize: "50px",
          fontWeight: "700",
        }}
      >
        {props.text}
      </H1>
    </MobileWrapper>
    <DesktopWrapper>
      <HeroText>
        <H1
          style={{
            color: "#304669",
            fontSize: "50px",
            fontWeight: "700",
          }}
        >
          {props.text}
        </H1>
      </HeroText>
    </DesktopWrapper>
  </>
);

const MobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.DARK_ORANGE};
  height: 150px;

  ${up("md")} {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  width: 100%;
  display: none;

  ${up("md")} {
    display: flex;
  }

  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/header-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
`;

const HeroText = styled.div``;
