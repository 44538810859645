import { Link } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";

export const MobileFooter = () => {
  return (
    <Wrapper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FooterLink to="contactus">Contact us</FooterLink>
          <FooterLink to="reportuser">Report a user</FooterLink>
          <FooterLink to="privacypolicy">Privacy policy</FooterLink>
          {/* <FooterLink to="donate">Donate to plunket</FooterLink> */}
          <FooterLink to="contactus">Advertise with us</FooterLink>
        </div>
        <img
          width={80}
          height={80}
          style={{ marginRight: "20px", marginTop: "10px" }}
          src="/assets/images/heart-white.png"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>
          <div
            style={{
              marginBottom: "4px",
              color: "white",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            © bellybutton 2022
          </div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}>
            Nourished By Plunket
            <div style={{ marginTop: "4px", color: "#ffffff" }}>
              <a href="https://frankworks.co.nz">Built by fw</a>
            </div>
          </div>
        </div>
        <Link to={"TermsConditions"} style={{ textAlign: "right" }}>
          Terms & conditions
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  color: white;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORS.DARK_ORANGE};
  text-align: left;
  ${up("lg")} {
    display: none;
  }

  a {
    text-decoration: none;
    color: white;
  }
`;

const FooterLink = styled(Link)`
  font-weight: bold;
  margin-bottom: 10px;
`;
