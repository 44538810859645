import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../constants/colors";

interface ILinkProps {
  size?: "sm" | "md" | "lg";
  type?: "primary" | "secondary" | "tertiary";
  href: string;
  text: string;
}

export const StyledLink = (props: ILinkProps) => {
  const { size, type, text, href } = props;
  return (
    <Link style={{ textDecoration: "none" }} to={href}>
      {/* @ts-ignore */}
      <StyledLinkWrapper type={type}>{text}</StyledLinkWrapper>
    </Link>
  );
};

const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  width: 128px;
  max-width: 10vw;
  min-height: 34px;
  font-weight: bold;
  font-size: ${(props: any) => {
    switch (props.type) {
      case "primary":
        return "0.625rem";
      case "secondary":
        return "0.625rem";
      case "tertiary":
        return "1.5rem";
    }
  }};

  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: ${(props: any) =>
    props.type === "tertiary" ? "white" : COLORS.DARK_ORANGE};
  box-shadow: none;
  color: ${(props: any) => {
    switch (props.type) {
      case "primary":
        return "white";
      case "tertiary":
        return COLORS.NAVY_BLUE;
      default:
        return COLORS.DARK_ORANGE;
    }
  }};
  background-color: ${(props: any) => {
    switch (props.type) {
      case "primary":
        return COLORS.DARK_ORANGE;
      default:
        return "white";
    }
  }};
  :hover {
    cursor: pointer;
    /* Change background color and text color */
    background-color: ${(props: any) => {
      switch (props.type) {
        case "primary":
          return "white";
        case "secondary":
          return COLORS.DARK_ORANGE;
        case "tertiary":
        default:
          return "white";
      }
    }};
    color: ${(props: any) => {
      switch (props.type) {
        case "primary":
          return COLORS.DARK_ORANGE;
        case "secondary":
          return "white";
        case "tertiary":
          return COLORS.DARK_ORANGE;
        default:
          return "white";
      }
    }};
  }
`;
