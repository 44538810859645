import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "./Button";
import { H1 } from "./Header";

export const AboutUsSection = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <AboutSection>
        <Left
          alt="child hiding behind their own hands"
          src="/assets/images/aboutus2compressed.webp"
        />
        <Right>
          <HeadingArea>
            <HandIcon />
            <H1
              style={{
                color: "#304669",
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "left",
                marginLeft: "16px",
              }}
            >
              About us
            </H1>
          </HeadingArea>
          <Description>
            Nourished by Whānau Āwhina Plunket, bellybutton was established with
            two key things in mind: to connect whānau through a likeminded
            community of quality suppliers, de-clutterers and recyclers, and to
            provide a platform to encourage conscious consumerism: buy or sell,
            share or receive. <br></br>
            <br></br>Baby gear is expensive, and so often it’s not used very
            much (because those little darlings seem to keep growing!).
            Recognising an opportunity in Aotearoa to see quality products and
            services in one place or baby gear go around again, our hope is that
            bellybutton connects the parent community so that baby’s quality
            items are used more than once.
          </Description>
          <Buttons>
            <Button
              buttonType="button"
              onClick={() => {
                navigate("/contactus");
              }}
              type="blueborder"
              text="Advertise with us"
            />
          </Buttons>
        </Right>
      </AboutSection>
    </Wrapper>
  );
};

const HandIcon = () => (
  <img src="/assets/images/handheart.svg" alt="Hand Heart Icon" />
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  ${up("lg")} {
    margin-bottom: 120px;
  }
`;

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;

  ${up("lg")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 90px;
  }

  align-items: center;
  width: 1442px;
  max-width: 100vw;
`;

const HeadingArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 42px;
`;

const Description = styled.p`
  color: #304669;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  line-height: 30px;
  margin-bottom: 42px;
`;

const Left = styled.img`
  margin-bottom: 24px;
  object-fit: cover;
  width: 100%;

  ${up("lg")} {
    width: 721px;
    max-width: 50vw;
    height: 550px;
    margin-bottom: 0;
  }
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
`;

const Right = styled.div`
  margin-left: 32px;
  margin-right: 32px;

  ${up("lg")} {
    margin-left: 0;
    margin-right: 60px;
  }

  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
`;
