export enum COLORS {
  LIGHT_GREY = "#FEFBFA",
  BABY_BLUE = "#F1F9FF",
  OFF_WHITE = "#D16D3D08",
  NAVY_BLUE = "#304669",
  OFF_WHITE2 = "#D16D3D07",
  DARK_ORANGE = "#F27442",
  LIGHT_CHARCOAL_GREY = "#95989A",
  LIGHT_BLUE = "#BCE0FD",
  OFF_WHITE3 = "#D16D3D1A",
  LIGHT_PINK = "#FBF0EB",
  DARK_CHARCOAL_GREY = "#7F7F7F",
  BURGUNDY_RED = "#8A0000",
}
