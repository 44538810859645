import { Link } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";

interface ISidebarProps {
  expandAll?: boolean;
  options: ISidebarOption[];
  selectedOptionTitle?: string;
  selectedSubOptionTitle?: string;
}

export interface ISidebarOption {
  title: string;
  link?: string;
  subOptions: { title: string; link: string }[];
}

export const Sidebar = (props: ISidebarProps) => {
  const { options, expandAll, selectedOptionTitle, selectedSubOptionTitle } =
    props;

  return (
    <Wrapper>
      <ContentArea>
        {options.map((option, index) => {
          return (
            <SidebarOption key={index}>
              <OptionHeading>
                {!option.link && option.title}
                {option.link && <Link to={option.link}>{option.title}</Link>}
              </OptionHeading>
              {option.subOptions.map((subOption, index) => {
                return (
                  <SuboptionHeading key={index}>
                    <Link to={subOption.link}>{subOption.title}</Link>
                  </SuboptionHeading>
                );
              })}
            </SidebarOption>
          );
        })}
      </ContentArea>
    </Wrapper>
  );
};

const ContentArea = styled.div`
  padding: 32px;
`;

const SidebarOption = styled.div``;

const OptionHeading = styled.h1`
  font-weight: bold;
`;

const SuboptionHeading = styled.p`
  margin-left: 16px;
`;

const Wrapper = styled.div`
  display: none;

  ${up("lg")} {
    display: block;
  }

  text-align: left;
  min-width: 235px;
  background-color: ${COLORS.OFF_WHITE3};
  color: ${COLORS.NAVY_BLUE};

  font-size: 16px;
  line-height: 35px;

  a {
    color: ${COLORS.NAVY_BLUE};
  }
`;
