/* eslint-disable react/no-unescaped-entities */
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";

const TermsConditionsHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf0eb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 180px;
  margin-bottom: 60px;

  ${up("lg")} {
    height: 220px;
  }
`;

const TermsConditionsContent = styled.div`
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;

  ${up("lg")} {
    width: 60%;
  }
`;

const TermsConditionsText = styled.div`
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  line-height: 22px;
`;

const TermsConditionsHeroText = styled.div`
  display: flex;
  width: 80%;
  align-items: center;

  ${up("lg")} {
    width: 60%;
  }
`;

const StyledH1 = styled.h1`
  color: #304669;
  font-size: 40px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

const StyledH2InText = styled.h2`
  color: #304669;
  font-size: 20px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 40px;
    margin-top: 10px;
  }
`;

// PAGE LAYOUT EXPORT

export const TermsConditions = () => {
  return (
    <div>
      <TermsHeroWrap />
      <LayoutBodyFullwidth>
        <TermsContentWrap />
      </LayoutBodyFullwidth>
    </div>
  );
};

const TermsHeroWrap = () => {
  return (
    <TermsConditionsHero>
      <TermsConditionsHeroText>
        <StyledH1>Terms & Conditions</StyledH1>
      </TermsConditionsHeroText>
    </TermsConditionsHero>
  );
};

const TermsContentWrap = () => {
  return (
    <TermsConditionsContent>
      <TermsConditionsText>
        <p
          style={{
            color: "#304669",
            fontSize: "16px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            {" "}
            We are not big players, we are a community of like-minded people
            going through the same stages of life, we care about each other, are
            respectful and kind. We do have to include some legal bits so;
          </span>{" "}
          <br></br>
          <br></br>
          By using this website, you acknowledge that you are bound by these
          terms and rules of use. If you don&#39;t accept these, you must not
          use this website. In these terms: <br></br>
          <br></br>- "You" means the person using this website <br></br>- "Your"
          has a corresponding meanining <br></br>- "We" means bellybutton. "Our"
          and "us" have a corresponding meaning. <br></br>
          <br></br>
          We may change the information on this website, including these terms,
          at any time. By continuing to use this website after any changes have
          been made, you acknowledge that you will continue to be bound by these
          terms. <br></br>
          <br></br>
          We reserve the right to add or remove material, and correct errors. If
          you find any errors please get in touch on innie@bellybutton.nz{" "}
          <br></br>
          <br></br>
          We will try to make sure that the information on this website is
          accurate and up to date. We do not guarantee that the information we
          provide is reliable, accurate, or suitable for what you may want to
          use it for.<br></br>
          We may discontinue site availability at any time without notice.
          <br></br>
          <br></br>
          You use this website at your own risk. We will not be liable in
          contract, tort or otherwise for any loss or damage (direct or
          otherwise) you suffer in connection with your use of this website. We
          exclude any condition or warranty that could be implied into these
          terms (but only to the extent that this is allowed by law). <br></br>
          <br></br>
          This website may contain hypertext links to other websites that are
          not operated by us. These links are provided for your convenience and
          interest only. The inclusion of a link does not imply our endorsement
          of the site or any association with its operators. <br></br>
          <br></br>
          We hold all copyright and other intellectual property rights in this
          website. You may use the information on this website and reproduce it
          (electronically or in hard copy) for your personal use only. In all
          other circumstances, you must get written permission from us before
          you use, send or reproduce any of the information on this website.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Here are our basic rules of use:
          </span>
          <br></br>
          1. If your item has safety standards, please note these on the add
          these details on the listing. If items aren’t safe or have expired,
          please dispose of elsewhere. For more information on safety standards
          and keeping kids safe:{" "}
          <a
            style={{
              color: "#304669",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            href="https://www.productsafety.govt.nz/for-consumers/keeping-kids-safe/"
          >
            https://www.productsafety.govt.nz/for-consumers/keeping-kids-safe/
          </a>
          <br></br>
          2. Advertising products for sale on this site does not mean that these
          are endorsed or supported by bellybutton or Whānau āwhina Plunket.{" "}
          <br></br>
          3. Be kind, respectful and considerate <br></br>
          4. Always clean your items before sending them. <br></br>
          If possible, pack them with love and add a note. Cleaning is non
          negotiable, no one wants to receive something dirty.<br></br>
          5. Have items ready to go. If your item is no longer available - for
          any reason - please withdraw the listing. <br></br>
          6. Accept the sale promptly. We encourage sellers to accept orders
          ASAP, ideally within 24 hours of receiving the request. Once the sale
          is confirmed, please send or arrange collection of your items as
          quickly as you can. 48 hours is ideal. One week is acceptable, unless
          prior agreement with the buyer.<br></br>
          7. Proof of postage - please keep hold of it. We also encourage you to
          share tracking details with the buyer so they can keep track of the
          parcel.<br></br>
          8. Communication is key. Try to check your messages regularly to
          ensure buyers questions or concerns are answered.
          <br></br>
          <br></br>
          <StyledH2InText>
            Huggies 6 month supply of nappies T&C's
          </StyledH2InText>
          <br></br>
          <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Closing Date:
          </span>
          Thursday July 14th at 1700 (5pm) <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            How to enter:
          </span>
          Simply create an account on the bellybutton page<br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Prize details:
          </span>
          6 months supply of huggies nappies*<br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Total prize value $812
          </span>{" "}
          <br></br>
          16 Jumbo Nappy Vouchers ($672) <br></br>
          24 packs of Huggies Thick Baby Wipes 80's Pack ($140) <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Entry restrictions:
          </span>{" "}
          Entrants must be New Zealand residents <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Draw:
          </span>{" "}
          All accounts created before the closing date woll be eligible for the
          supervised draw on Friday 15th July. <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Notification:
          </span>{" "}
          The winner will be notified via email on the day of the draw and
          arragements will be made to supply vouchers directly to them <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Exchange:
          </span>{" "}
          The prize is not able to be transferred or exchanged for a cash
          equivalent <br></br>
          <span
            style={{
              color: "#304669",
              fontWeight: "bold",
              paddingRight: "2px",
            }}
          >
            Location:
          </span>{" "}
          Entrants must be New Zealand residents<br></br>
          <br></br>
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Happy buying, selling and giving!
          </span>
        </p>
      </TermsConditionsText>
    </TermsConditionsContent>
  );
};
