import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { H1 } from "../components/Header";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { ListingTilePreview } from "../components/ListingTilePreview";
import { COLORS } from "../constants/colors";
import { useListing } from "../hooks/useListing";

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.DARK_ORANGE};
  height: 150px;
  margin-bottom: 60px;
  font-size: 2rem;

  h1 {
    color: white !important;
  }

  ${up("md")} {
    h1 {
      font-size: 60px;
      color: ${COLORS.NAVY_BLUE} !important;
    }
    background-image: url("/assets/images/header-image.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 229px;
    margin-bottom: 40px;
  }
`;

const Main = styled.div`
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;

  ${up("md")} {
    flex-direction: row;
  }
`;

const Preview = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 40px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${up("lg")} {
    flex-direction: row;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

const Title = styled.div`
  color: ${COLORS.DARK_ORANGE};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1rem;
  ${up("md")} {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  ${up("lg")} {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;

const Icon = styled.img`
  width: 43px;
  height: 43px;
  margin-right: 20px;
`;

const ButtonItem = styled.div`
  width: 288px;
  height: 63px;
  margin-bottom: 16px;
  margin-right: 20px;
  button {
    font-size: 0.8rem;
  }
`;

const ParagraphText = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  line-height: 1.3rem;
  color: ${COLORS.NAVY_BLUE};
  ${up("md")} {
    font-size: 1.5rem;
  }
`;

export const WithdrawListing = () => {
  return (
    <div>
      <HeroWrap />
      <LayoutBody>
        <LayoutBodyFullwidth>
          <MainWrap />
        </LayoutBodyFullwidth>
      </LayoutBody>
    </div>
  );
};

const HeroWrap = () => {
  return (
    <div>
      <Hero>
        <H1 style={{ color: "#304669", fontWeight: "bold" }}>
          Withdraw your listing
        </H1>
      </Hero>
    </div>
  );
};

const MainWrap = () => {
  const { id } = useParams();
  const { closeListing, getListingById, listingById } = useListing();
  useEffect(() => {
    if (!id) {
      return;
    }
    getListingById(id);
  }, [id, getListingById]);

  const navigate = useNavigate();
  const onClickConfirm = () => {
    if (!id) {
      return;
    }

    closeListing(id, () => {
      navigate("/withdrawlistingconfirm");
    });
  };
  const onClickCancel = () => {
    navigate("/listing/" + id);
  };
  return (
    <Main>
      <Preview>
        {listingById && <ListingTilePreview listing={listingById} />}
      </Preview>
      <Info>
        <Heading>
          <Icon src="/assets/images/alerticon.svg" />
          <Title>Do you really want to remove listing title?</Title>
        </Heading>
        <ParagraphText>
          You are about to withdraw this listing from sale. Doing this will take
          down your item and delete it from your listings.
        </ParagraphText>
        <ParagraphText>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            This listing will be permanently deleted - this action cannot be
            undone.
          </div>
        </ParagraphText>
        <ParagraphText>
          If you want to proceed with taking down this listing click Confirm
          below. If you have changed your mind, click Cancel.
        </ParagraphText>
        <Buttons>
          <ButtonItem>
            <Button
              type="secondary"
              buttonType="button"
              text="CANCEL & GO BACK"
              onClick={onClickCancel}
            />
          </ButtonItem>
          <ButtonItem>
            <Button
              type="primary"
              buttonType="button"
              text="YES, I WANT TO WITHDRAW MY LISTING"
              onClick={onClickConfirm}
            />
          </ButtonItem>
        </Buttons>
      </Info>
    </Main>
  );
};

export default WithdrawListing;
