import styled from "styled-components";
import { COLORS } from "../constants/colors";

interface ISuccessTextProps {
  title: string;
  description: string;
}

const HeroImage = () => (
  <img
    width={120}
    height={120}
    src="/assets/images/bellybutton-hearticon.png"
    alt="Bellybutton Heart Icon"
  />
);

export const SuccessText = ({ title, description }: ISuccessTextProps) => {
  return (
    <Wrapper>
      <HeroImage />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Title = styled.h1`
  color: ${COLORS.DARK_ORANGE};
  font-weight: bold;
  font-size: 60px;
  line-height: 69px;
  margin-bottom: 33px;
  margin-top: 50px;
`;
const Description = styled.p`
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.NAVY_BLUE};
`;
