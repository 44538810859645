import { up } from "styled-breakpoints";
import styled from "styled-components";
import {
  ALL_CATEGORIES,
  ICategory,
  ISubCategory,
} from "../constants/categories";
import { COLORS } from "../constants/colors";

const Wrapper = styled.div`
  display: none;
  ${up("md")} {
    display: block;
  }
`;

const Category = styled.button`
  all: unset;
  display: block;
  color: ${COLORS.NAVY_BLUE};
  font-size: 25px;
  line-height: 48px;
  text-align: left;
  :focus {
    outline: solid 2px ${COLORS.NAVY_BLUE};
  }
  :hover {
    cursor: pointer;
  }
`;

const SubCategory = styled.ul`
  margin-left: 24px;
`;

interface IProps {
  setCategoryFilter: (category: ICategory) => void;
  setSubCategoryFilter: (subCategory: any) => void;
  categoryFilter: ICategory | undefined;
  subCategoryFilter: any;
}
export const CategorySidebar = (props: IProps) => {
  const {
    setCategoryFilter,
    categoryFilter,
    subCategoryFilter,
    setSubCategoryFilter,
  } = props;

  return (
    <Wrapper>
      {ALL_CATEGORIES.map((cat: ICategory) => (
        <Category
          type="button"
          id={"select " + cat.name}
          key={cat.apiName + "Sidebar"}
        >
          {categoryFilter && categoryFilter.name === cat.name && (
            <div style={{ fontWeight: "bold" }}>{cat.name}</div>
          )}
          <div
            onClick={() => {
              setCategoryFilter(cat);
              setSubCategoryFilter(undefined);
            }}
          >
            {!categoryFilter || categoryFilter.name !== cat.name
              ? cat.name
              : ""}
          </div>
          {categoryFilter === cat && (
            <SubCategory>
              {cat.subCategories?.map((subCat: ISubCategory) => (
                <>
                  {(!subCategoryFilter ||
                    subCategoryFilter.name !== subCat.name) && (
                    <li
                      onClick={() => {
                        setSubCategoryFilter(subCat);
                      }}
                      key={subCat.apiName}
                    >
                      {subCat.name}
                    </li>
                  )}
                  {subCategoryFilter && subCategoryFilter.name === subCat.name && (
                    <li style={{ fontWeight: "bold" }} key={subCat.apiName}>
                      {subCat.name}
                    </li>
                  )}
                </>
              ))}
            </SubCategory>
          )}
        </Category>
      ))}
    </Wrapper>
  );
};
