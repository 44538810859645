import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { H2 } from "../components/Header";
import { Button } from "./Button";

interface INoResultsProps {
  type: "listings" | "purchases";
}

export const NoResults = (props: INoResultsProps) => {
  const navigate = useNavigate();
  const { type } = props;
  const subTitle =
    type === "listings"
      ? "You haven't listed anything for sale yet."
      : "You haven't made any purchases yet.";
  const buttonText =
    type === "listings" ? "List something now" : "Browse all listings";
  const onClickButton = () => {
    if (type === "listings") {
      navigate("/sell");
    } else {
      navigate("/buy");
    }
  };

  return (
    <Wrapper>
      <SadFaceImg>
        <OopsImg src="/assets/images/sadtear.svg" alt="Sad Tear Face" />
      </SadFaceImg>
      <MainContent>
        <Title>Nothing to see here!</Title>
        <SubTitle>{subTitle}</SubTitle>
        <ButtonWrap>
          <Button
            onClick={onClickButton}
            type="bluebg"
            text={buttonText}
            buttonType="button"
          />
        </ButtonWrap>
      </MainContent>
    </Wrapper>
  );
};

const ButtonWrap = styled.div`
  width: 550px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;
  width: 900px;
`;

const SadFaceImg = styled.div`
  margin-left: -150px;
`;

const MainContent = styled.div`
  margin-left: -150px;
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: bold;
  color: #f27442;
  text-align: left;
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  font-size: 30px;
  font-weight: normal;
  color: #304669;
  text-align: left;
  margin-bottom: 20px;
`;

const OopsImg = styled.img`
  width: 200px;
`;
