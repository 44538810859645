import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { ParentingAppsGrid } from "../components/ParentAppsGrid";
import { ParentingHero } from "../components/ParentingHero";

export const ParentingApps = () => {
  return (
    <LayoutBodyFullwidth>
      <ParentingHero />
      <LayoutBody>
        <ParentingAppsGrid />
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};
