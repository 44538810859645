import { ErrorBoundary } from "react-error-boundary";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { getCatNameByApiName } from "../constants/categories";
import { COLORS } from "../constants/colors";
import { ALL_LOCATIONS } from "../constants/locations";
import { useAuth } from "../hooks/useAuth";
import { IListing, useListing } from "../hooks/useListing";
import { getListingDateAsStringFromListing } from "../utils/date";
import { getImageURLByListing } from "../utils/images";
import { formatPriceFromPriceObject } from "../utils/price";
import { getUsernameForListing } from "../utils/users";
import { ErrorFallback } from "./ErrorFallback";
import { H2, H4 } from "./Header";

const Wrapper = styled.a`
  overflow: hidden;
  scroll-snap-align: start;
  border: solid 1px #7f7f7f59;
  border-radius: 8px;
  color: ${COLORS.NAVY_BLUE};
  display: grid;
  min-width: 300px;
  max-width: 300px;

  background-color: ${COLORS.OFF_WHITE2};
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  /* Add drop shadow */
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);

  margin-right: 20px;

  ${up("lg")} {
    margin-right: 0;
  }
`;

const FeaturedWrapper = styled.a`
  min-width: 248px;
  scroll-snap-align: start;

  margin-right: 20px;

  ${up("lg")} {
    margin-right: 0;
  }

  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${COLORS.NAVY_BLUE};
  background-color: ${COLORS.LIGHT_GREY};
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const ImageWrapper = styled.img`
  width: 100%;
  height: 218px;
  object-fit: scale-down;
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 18px;
  margin: 0;
`;
const ListedAt = styled.h3`
  font-size: 12px;
  white-space: nowrap;
`;
const Location = styled.h2`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  margin: 0;

  img {
    margin-right: 8px;
  }
`;
const Price = styled.div`
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
`;
const FeaturedPrice = styled.div`
  margin: 0;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1.5rem;
`;
const ConditionBadge = styled.div`
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  background-color: ${COLORS.DARK_ORANGE};
  border-radius: 5px;
  padding: 5px;
  padding-left: 13px;
  padding-right: 13px;
  margin-right: 5px;
`;

const FeaturedConditionBadge = styled.div`
  position: absolute;
  font-size: 10px;
  color: white;
  text-align: center;
  font-weight: bold;
  border-radius: 6px;
  padding: 8px 24px 8px 24px;
  top: 16px;
  right: 20px;
  background-color: ${COLORS.DARK_ORANGE};
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  grid-template-columns: 2fr 1fr;
`;

const images = ["babycot", "nappy", "bottle", "teddy"];

const FeaturedTextArea = styled.div`
  background-color: ${COLORS.OFF_WHITE3};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  height: 380px;
`;

const IconRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  img {
    margin-right: 9px;
  }
`;

const BottomFeatureContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationIcon = () => (
  <img src="/assets/images/globe.svg" alt="Globe Icon" />
);
const UserIcon = () => (
  <img src="/assets/images/profile.svg" alt="Profile Icon" />
);

export const ListingTilePreview = ({
  listing,
  isFeatured,
}: {
  listing: IListing;
  isFeatured?: boolean;
}) => {
  const { price, title, description } = listing.attributes;
  const { allMetadata } = useListing();
  const { user } = useAuth();
  const MyUsername = user?.attributes?.profile?.displayName;
  // const navigate = useNavigate();
  const { location, category, condition } = listing.attributes.publicData;

  let imageUrl = `/assets/images/${
    images[Math.floor(Math.random() * images.length)]
  }.png`;

  const hasImage = listing?.relationships?.images?.data.length > 0;

  const categoryText = getCatNameByApiName(category);
  const username = getUsernameForListing(listing, allMetadata);
  const isListedByMe = username === MyUsername;

  if (hasImage) {
    imageUrl = getImageURLByListing(listing, allMetadata);
  }

  const relativeDate = getListingDateAsStringFromListing(listing);

  if (isFeatured) {
    return (
      <FeaturedWrapper
        href={`/listing/${listing.id.uuid}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ImageWrapper src={imageUrl} />
          <FeaturedTextArea>
            <div>
              <H4
                style={{
                  marginTop: "26px",
                  marginBottom: "20px",
                  fontSize: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {categoryText ? categoryText.toUpperCase() : "BOOKS"}
              </H4>
              <H2
                style={{
                  marginBottom: "12px",
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {title}
              </H2>
              <p
                style={{
                  overflow: "hidden",
                  height: "55px",
                  marginBottom: "15px",
                  marginTop: "22px",
                  color: COLORS.NAVY_BLUE,
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "left",
                }}
              >
                {description}
              </p>
            </div>

            <BottomFeatureContent>
              <FeaturedPrice style={{ fontSize: "20px", textAlign: "left" }}>
                {formatPriceFromPriceObject(price)}
              </FeaturedPrice>
              <IconRow>
                <LocationIcon />
                <H2
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  {/* If no location place empty character */}
                  {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
                  {!location && <p></p>}
                </H2>
              </IconRow>
              <IconRow>
                <UserIcon />
                <H2
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  {isListedByMe ? "Listed by you" : username}
                </H2>
              </IconRow>

              {/* Paragraph in italics */}

              <p
                style={{
                  marginTop: "17px",
                  textAlign: "left",
                  fontSize: "10px",
                }}
              >
                {/* Italics text */}
                <i> Listed {relativeDate}</i>
              </p>
            </BottomFeatureContent>
          </FeaturedTextArea>
          <FeaturedConditionBadge>
            {condition ? condition.toUpperCase() : "USED"}
          </FeaturedConditionBadge>
        </ErrorBoundary>
      </FeaturedWrapper>
    );
  }

  return (
    <Wrapper
      href={`/listing/${listing.id.uuid}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TextArea>
          <Row>
            <Location>
              <LocationIcon />
              {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
            </Location>
            <ListedAt>
              <i>Listed {relativeDate}</i>
            </ListedAt>
          </Row>
          <Row>
            <Title>{title}</Title>
          </Row>
          <Row>
            <Price>{formatPriceFromPriceObject(price)} </Price>
            <ConditionBadge>
              {condition ? condition.toUpperCase() : "USED"}
            </ConditionBadge>
          </Row>
        </TextArea>
        <ImageWrapper src={imageUrl} />
      </ErrorBoundary>
    </Wrapper>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
