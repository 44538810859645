import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { LayoutBody } from "./Layout";

export const ParentingHero = () => {
  return (
    <HeroBackground>
      <LayoutBody>
        <HeroWrapper>
          <HeroImg
            src="/assets/images/bellybutton-hearticon.svg"
            alt="Bellybutton Heart Icon"
          />
          <HeroTextArea>
            <HeroTitle>Parenting Apps</HeroTitle>
            <HeroSubtitle>
              A selection of apps available on the Google play store and iOS,
              hand-picked <br></br> and recommended by us, to help make your
              parenting journery a little easier.
            </HeroSubtitle>
          </HeroTextArea>
        </HeroWrapper>
      </LayoutBody>
    </HeroBackground>
  );
};

const HeroImg = styled.img`
  display: none;
  ${up("lg")} {
    display: flex;
    width: 145px;
  }
`;

const HeroBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf0eb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  height: 260px;

  ${up("lg")} {
    height: 363px;
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const HeroTitle = styled.h1`
  color: ${COLORS.NAVY_BLUE};
  text-align: left;
  font-weight: bold;
  margin-bottom: 18px;
  font-size: 24px;

  ${up("lg")} {
    font-size: 40px;
  }
`;

const HeroTextArea = styled.div`
  text-align: left;
  color: ${COLORS.NAVY_BLUE};
  margin-left: 18px;
`;

const HeroSubtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  ${up("lg")} {
    line-height: 24px;
    font-size: 20px;
  }
`;
