import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { Button } from "./Button";
import { H1 } from "./Header";
import { MobileMenuHero } from "./MobileMenuHero";

const DesktopHeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/heroimagesmaller.webp");
  background-size: cover;
  background-position: center-top;
  background-repeat: no-repeat;
  height: 697px;
  max-width: 100%;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    max-width: 320px;
    height: 597px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    max-width: 375px;
    height: 597px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    max-width: 426px;
    height: 597px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const HeroContent = styled.div`
  width: 50%;
  max-width: 724px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    max-width: 570px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    max-width: 570px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    max-width: 570px;
  }
`;

const HeroLogo = styled.img`
  width: 470px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    width: 270px;
    margin-left: -110px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    width: 270px;
    margin-left: -110px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    width: 270px;
    margin-left: -110px;
  }
`;

const SearchArea = styled.form`
  display: grid;
  grid-template-columns: 70% 30%;
  margin-bottom: 20px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const SearchBoxInput = styled.input`
  padding-left: 32px;
  margin-right: 16px;
  ::placeholder {
    font-weight: bold;
    color: ${COLORS.NAVY_BLUE};
  }
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    width: 270px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    width: 270px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    width: 270px;
  }
`;

const SearchBoxButton = styled.div`
  display: flex;
  width: 238px;
  height: 54px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    padding-top: 10px;
    max-width: 278px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    padding-top: 10px;
    max-width: 278px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    padding-top: 10px;
    max-width: 278px;
  }
`;

export const HomeHero = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = ({ keywords }: any) => {
    navigate("/buy?keywords=" + keywords);
  };

  return (
    <>
      <MobileMenuHero />
      <DesktopHeroWrapper>
        <HeroContent>
          {/* <HeroLogo src="/assets/images/bellybutton-header.svg" /> */}
          <SearchArea onSubmit={handleSubmit(onSubmit)}>
            <SearchBoxInput
              {...register("keywords", { required: true })}
              placeholder="What do you need?"
            />
            <SearchBoxButton>
              <Button
                buttonType="button"
                onClick={handleSubmit(onSubmit)}
                type="primary"
                text="SEARCH"
              />
            </SearchBoxButton>
            <input type="submit" hidden />
          </SearchArea>
          <H1
            style={{
              color: "white",
              fontSize: "24px",
              fontWeight: "normal",
              lineHeight: "40px",
              marginBottom: "32px",
            }}
          >
            Innies looking for new or pre-loved possessions, outies with quality
            belongings taking up space. Bellybutton is a connection point for
            whānau far and wide to pass on or find new treasures or taputapu
            pēpī (baby gear) that still has life to give.
          </H1>
        </HeroContent>
      </DesktopHeroWrapper>
    </>
  );
};
