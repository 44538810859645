interface IParentingApp {
  imgUrl: string;
  title: string;
  isIOSSupported: boolean;
  isAndroidSupported: boolean;
  description: string;
  features: string[];
  link: string;
}

export const PARENTING_APPS: IParentingApp[] = [
  {
    title: "Raising Children",
    imgUrl: "/assets/images/RaisingChild.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description:
      "Made in NZ newborn to primary parenting tips, video library & more",
    features: [
      "Parenting tips",
      "Video library",
      "Newborns to primary",
      "Professional and celebrity input",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://raisingchildren.org.nz/",
  },
  {
    title: "Well Child",
    imgUrl: "/assets/images/wellchild.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description:
      "Education & information for NZ parents; can be used with any health book",
    features: [
      "Education & Information",
      "Reminders",
      "Can be used with my health book",
      "Aimed at NZ parents",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.wellchild.org.nz/",
  },
  {
    title: "Whare Kahika",
    imgUrl: "/assets/images/wharekahika.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Helping NZ parents identify potential safety risks at home",
    features: [
      "Identify potential risks to children",
      "Aimed at NZ parents",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.healthnavigator.org.nz/apps/w/whare-kahik%C4%81-home-safety-app/",
  },
  {
    title: "Positively Pregnant",
    imgUrl: "/assets/images/positivelypregnant.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Improve parenting confidence & build social support",
    features: [
      "Improve parenting confidence",
      "Build social support",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.positivelypregnant.org.nz/",
  },
  {
    title: "Pregnancy + Tracker",
    imgUrl: "/assets/images/pregnancytracker.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description:
      "Education & information for NZ parents; can be used with any health book",
    features: [
      "The worlds leading pregnancy app",
      "Expert advice",
      "Mother care tips",
      "Track baby development",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://philips-digital.com/pregnancy-new/",
  },
  {
    title: "Emergency Q",
    imgUrl: "/assets/images/emergencyqlogo.png",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Get an idea of the wait time before you get there",
    features: [
      "Forecasts wait & treatment times for non-emergency patients at A&E clinics around New Zealand",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.emergencyq.com/",
  },
  {
    title: "Support Crew",
    imgUrl: "/assets/images/supportcrewlogo.png",
    isIOSSupported: true,
    isAndroidSupported: true,
    description:
      "Coordinate and access support from friends and family via this website",
    features: [
      "www.supportcrew.co",
      "Coordinates support for family and friends in need",
    ],
    link: "https://www.supportcrew.co/",
  },
  {
    title: "Move it Mama",
    imgUrl: "/assets/images/loveitmamalogo.png",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Pregnant and post-partum exercise and wellness",
    features: [
      "www.moveitmama.co.nz",
      "Exercise, wellness for pregnant and postpartum mamas",
      "Community",
      "Tips & tricks",
    ],
    link: "https://www.moveitmama.co.nz/",
  },
  {
    title: "Baby 2 Body",
    imgUrl: "/assets/images/baby2body.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Pregnancy well-being and exercise",
    features: [
      "Prenatal workouts",
      "Postnatal workouts",
      "Recipes and mindfulness tailored to your stage, goals and preferences",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.baby2body.com/",
  },
  {
    title: "Daddy Up",
    imgUrl: "/assets/images/daddyup.png",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "A field guide for pregnancy for Dads",
    features: [
      "Progress",
      "Customisable checklist",
      "Contraction counter",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://daddyup.com/",
  },
  {
    title: "Food Switch",
    imgUrl: "/assets/images/foodswitc.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "A NZ-based food database",
    features: [
      "Barcode scanning",
      "Can work with certain diets",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.healthnavigator.org.nz/apps/f/foodswitch-new-zealand-app/",
  },
  {
    title: "Mama Aroha",
    imgUrl: "/assets/images/mamaaroha.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Breastfeeding principles and support",
    features: [
      "Education & information about breastfeeding",
      "Advice",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.mamaaroha.co.nz/",
  },
  {
    title: "Manaaki Ora",
    imgUrl: "/assets/images/manaakiora.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Guidance and self-help tips to improve wellbeing",
    features: [
      "Help others going through hard times",
      "New Zealand based",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.healthnavigator.org.nz/apps/m/manaaki-ora-app/",
  },
  {
    title: "Sleep Well",
    imgUrl: "/assets/images/sleepwell.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description:
      "Sleep tracking, lullabies & sleep support for you and your baby",
    features: [
      "Australia's leading baby sleep, settling and routine app",
      "Endorsed sleep app",
      "Sleep schedule tracking",
      "Smart planner",
      "White noise, lullabies and sleep sounds",
      "Tips and coaching",
      // "Available in Google Play and itunes",
    ],
    link: "https://www.sleepwellbaby.io/",
  },
  {
    title: "Smiling Mind",
    imgUrl: "/assets/images/smilingmind.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Guided meditation and mindfulness for 7 years old and up",
    features: [
      "Tailored programs for different age groups",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://www.smilingmind.com.au/",
  },
  {
    title: "Solid Starts",
    imgUrl: "/assets/images/solidstarts.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Helps you to introduce solids to baby",
    features: [
      "Search through the food database",
      "Progress tracker",
      // "Available in Google Play and itunes",
      "Free",
    ],
    link: "https://solidstarts.com/",
  },
  {
    title: "Well Revolution",
    imgUrl: "/assets/images/wellrevolution.jpg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description: "Virtual health consultations",
    features: [
      "Doctor & patient virtual consults",
      "Request prescriptions",
      "Billing",
      // "Available in Google Play and itunes",
    ],
    link: "https://wellrevolution.co.nz/",
  },
  {
    title: "iKidz ",
    imgUrl: "/assets/images/iKidz-logo.svg",
    isIOSSupported: true,
    isAndroidSupported: true,
    description:
      "An exciting new mobile app has just launched in NZ to connect families to providers of children’s activities, classes, and events!",
    features: [
      "The iKidz Go mobile app includes, but is not limited to sports, music, art, recreation and educational classes, activities, and family-friendly events.",
      "The app was designed and developed by parents, for parents, as we identified a need to search, enrol and manage after-school activities in one central location.",

      // "Available in Google Play and itunes",
    ],
    link: "https://www.ikidz.co.nz/",
  },
];
