import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { device } from "../constants/device";
import { PARENTING_APPS } from "../constants/parentingApps";

export const ParentingAppsGrid = () => (
  <AppsGrid>
    {PARENTING_APPS.map((app, index) => (
      <AppWrapper key={app.title} href={app.link}>
        <AppsImage imgUrl={app.imgUrl} />
        <AppsInfoWrapper>
          <AppsInfo>
            <Title>{app.title}</Title>
            <AppsParagraphText>{app.description}</AppsParagraphText>
            <FeaturesList>
              {app.features.map((feature: string) => (
                <li key={feature}>{feature}</li>
              ))}
            </FeaturesList>
          </AppsInfo>
        </AppsInfoWrapper>
      </AppWrapper>
    ))}
  </AppsGrid>
);

const AppWrapper = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

const AppsImage = styled.div<{ imgUrl: string }>`
  background-image: url(${(props) => props.imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  width: 220px;
  height: 220px;
`;

const AppsInfoWrapper = styled.div`
  display: flex;
  grid-template-columns: 80% 20%;

  @media ${device.mobileS} {
    grid-template-columns: 100%;
  }
`;

const AppsInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const AppsGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 220px);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  margin-top: 70px;
  margin-bottom: 154px;
`;

const AppsParagraphText = styled.div`
  margin-bottom: 10px;
  margin-right: 8px;
  color: #f27442;
  font-style: italic;
  line-height: 20px;
  font-size: 16px;
`;

const Title = styled.h1`
  color: ${COLORS.DARK_ORANGE};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const FeaturesList = styled.ul`
  color: ${COLORS.NAVY_BLUE};

  li {
    /* Make the list decoration a solid round ball */
    list-style-type: disc;
    line-height: 24px;
    font-size: 16px;
  }
`;
