import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import { AuthProvider } from "./components/AuthProvider";
import { Footer } from "./components/Footer";
import { Layout } from "./components/Layout";
import { ListingProvider } from "./components/ListingProvider";
import { Navigation } from "./components/Navigation";
import { ParentingApps } from "./screens/ParentingApps";
import { RequireAuth } from "./components/RequireAuth";
import ScrollToTop from "./components/ScrollToTop";
import { TransactionProvider } from "./components/TransactionProvider";
import { Buy } from "./screens/Buy";
import ChangeEmail from "./screens/ChangeEmail";
import ChangeEmailConfirm from "./screens/ChangeEmailConfirmation";
import ChangeLocationConfirm from "./screens/ChangeLocationConfirm";
import ChangePassword from "./screens/ChangePassword";
import ChangePasswordConfirm from "./screens/ChangePasswordConfirm";
import ChangeRegion from "./screens/ChangeRegion";
import ConfirmPurchase from "./screens/ConfirmPurchase";
import ContactUs from "./screens/ContactUs";
import { EditListing } from "./screens/EditListing";
import EditListingConfirm from "./screens/EditListingConfirm";
import { Faq } from "./screens/Faq";
import FeaturedListings from "./screens/FeaturedListings";
import { ForgotPassword } from "./screens/ForgotPassword";
import GiveFeedback from "./screens/GiveFeedback";
import HomePage from "./screens/Home";
import { Listing } from "./screens/Listing";
import ListingLiveConfirmed from "./screens/ListingLiveConfirmed";
import ListingPreview from "./screens/ListingPreview";
import { LoginPage } from "./screens/LoginPage";
import { Logout } from "./screens/Logout";
import { Account } from "./screens/Account";
import MyDetails from "./screens/MyDetails";
import { MyFeedback } from "./screens/MyFeedback";
import MyListings from "./screens/MyListings";
import MyPurchases from "./screens/MyPurchases";
import { Page404 } from "./screens/Page404";
import { PrivacyPolicy } from "./screens/PrivacyPolicy";
import ReportUser from "./screens/ReportUser";
import { CreateListing } from "./screens/CreateListing";
import { SellRegister } from "./screens/SellRegister";
import { Stylesheet } from "./screens/Stylesheet";
import { TermsConditions } from "./screens/TermsConditions";
import WithdrawListing from "./screens/WithdrawListing";
import WithdrawListingConfirm from "./screens/WithdrawListingConfirm";
import { defaultTheme } from "./style/defaultTheme";
import { GlobalStyle } from "./style/globalStyle";
import "./style/reset.css";
import { VerifyEmail } from "./screens/VerifyEmail";
import { Analytics } from "./components/Analytics";
import { Helmet } from "react-helmet";

const GlobalStyleWrapper = styled.div`
  font-family: "Arial";
`;

const Title = ({ title }: { title: string }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

export const App = () => {
  return (
    <RecoilRoot>
      <GlobalStyle />
      <ThemeProvider theme={defaultTheme}>
        <AuthProvider>
          <ListingProvider>
            <TransactionProvider>
              <GlobalStyleWrapper>
                <div className="App">
                  <BrowserRouter>
                    <Analytics />
                    <ScrollToTop>
                      <Layout>
                        <Navigation />
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <>
                                <Title title="Buy & Sell New & Preloved Baby Items" />
                                <HomePage />
                              </>
                            }
                          />
                          <Route
                            path="sell"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Sell" />
                                  <CreateListing />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="edit-listing/:id"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Sell" />
                                  <EditListing />
                                </>
                              </RequireAuth>
                            }
                          />

                          <Route
                            path="/buy/:categoryApiName"
                            element={
                              <>
                                <Title title="Browse" />
                                <Buy />
                              </>
                            }
                          />
                          <Route
                            path="/buy"
                            element={
                              <>
                                <Title title="Browse" />
                                <Buy />
                              </>
                            }
                          />
                          <Route
                            path="/sellregister"
                            element={
                              <>
                                <Title title="Register" />
                                <SellRegister />
                              </>
                            }
                          />
                          <Route
                            path="account/:id"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="View account" />
                                  <Account />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="myaccount"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="View account" />
                                  <Account />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="mydetails"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="My details" />
                                  <MyDetails />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="myfeedback"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="View feedback" />
                                  <MyFeedback />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="mylistings"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Listings" />
                                  <MyListings />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="mypurchases"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Purchases" />
                                  <MyPurchases />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="withdrawlisting/:id"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Withdraw listing" />
                                  <WithdrawListing />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="listingpreview/:id"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Preview listing" />
                                  <ListingPreview />
                                </>
                              </RequireAuth>
                            }
                          />

                          <Route
                            path="give-feedback/:transactionId"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Give feedback" />
                                  <GiveFeedback />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="editlistingconfirm"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Confirm changes" />
                                  <EditListingConfirm />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="withdrawlistingconfirm"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Confirm withdraw" />
                                  <WithdrawListingConfirm />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="verify-email"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Verify email" />
                                  <VerifyEmail />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="listingliveconfirmed/:id"
                            element={
                              <RequireAuth>
                                <>
                                  <Title title="Listing live" />
                                  <ListingLiveConfirmed />
                                </>
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="faq"
                            element={
                              <>
                                <Title title="FAQs" />
                                <Faq />
                              </>
                            }
                          />
                          <Route
                            path="termsconditions"
                            element={
                              <>
                                <Title title="Terms & Conditions" />
                                <TermsConditions />
                              </>
                            }
                          />
                          <Route
                            path="privacypolicy"
                            element={
                              <>
                                <Title title="Privacy policy" />
                                <PrivacyPolicy />
                              </>
                            }
                          />
                          <Route
                            path="login"
                            element={
                              <>
                                <Title title="Login" />
                                <LoginPage />
                              </>
                            }
                          />
                          <Route
                            path="parentingapps"
                            element={
                              <>
                                <Title title="Parenting apps" />
                                <ParentingApps />
                              </>
                            }
                          />

                          <Route path="/stylesheet" element={<Stylesheet />} />
                          <Route
                            path="successfulpurchase"
                            element={
                              <>
                                <Title title="Purchase success" />
                                <ConfirmPurchase />
                              </>
                            }
                          />
                          <Route
                            path="contactus"
                            element={
                              <>
                                <Title title="Contact us" />
                                <ContactUs />
                              </>
                            }
                          />
                          <Route
                            path="changepassword"
                            element={
                              <>
                                <Title title="Change password" />
                                <ChangePassword />
                              </>
                            }
                          />
                          <Route
                            path="reset-password"
                            element={
                              <>
                                <Title title="Change password" />
                                <ChangePassword />
                              </>
                            }
                          />
                          <Route
                            path="forgotpassword"
                            element={
                              <>
                                <Title title="Forgot password" />
                                <ForgotPassword />
                              </>
                            }
                          />
                          <Route
                            path="changeemail"
                            element={
                              <>
                                <Title title="Change email" />
                                <ChangeEmail />
                              </>
                            }
                          />
                          <Route
                            path="changeregion"
                            element={
                              <>
                                <Title title="Change region" />
                                <ChangeRegion />
                              </>
                            }
                          />
                          <Route
                            path="reportuser"
                            element={
                              <>
                                <Title title="Report user" />
                                <ReportUser />
                              </>
                            }
                          />

                          <Route
                            path="featuredlistings"
                            element={
                              <>
                                <Title title="Featured listings" />
                                <FeaturedListings />
                              </>
                            }
                          />

                          <Route
                            path="listing/:id"
                            element={
                              <>
                                <Title title="View listing" />
                                <Listing />
                              </>
                            }
                          />
                          <Route
                            path="successfulpurchase/:id"
                            element={
                              <>
                                <Title title="Successful purchase" />
                                <ConfirmPurchase />
                              </>
                            }
                          />
                          <Route
                            path="changelocationconfirm"
                            element={
                              <>
                                <Title title="Confirm location change" />
                                <ChangeLocationConfirm />
                              </>
                            }
                          />
                          <Route
                            path="changeemailconfirm"
                            element={
                              <>
                                <Title title="Confirm email change" />
                                <ChangeEmailConfirm />
                              </>
                            }
                          />
                          <Route
                            path="changepasswordconfirm"
                            element={
                              <>
                                <Title title="Confirm password change" />
                                <ChangePasswordConfirm />
                              </>
                            }
                          />
                          <Route
                            path="logout"
                            element={
                              <>
                                <Title title="Logout" />
                                <Logout />
                              </>
                            }
                          />
                          <Route
                            path="*"
                            element={
                              <>
                                <Title title="404" />
                                <Page404 />
                              </>
                            }
                          />
                        </Routes>
                        <Footer />
                      </Layout>
                    </ScrollToTop>
                  </BrowserRouter>
                </div>
              </GlobalStyleWrapper>
            </TransactionProvider>
          </ListingProvider>
        </AuthProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};
