import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { ICategory } from "../constants/categories";
import { CONDITIONS } from "../constants/conditions";
import { useListing } from "../hooks/useListing";
import { getCategoryByApiName } from "../utils/categories";
import { Button } from "./Button";
import { CategorySidebar } from "./CategorySidebar";
import { LayoutBody, LayoutBodyFullwidth } from "./Layout";
import { ListingsGridWithSideContent } from "./ListingsGridWithSidecontent";
import { SectionHeading } from "./SectionHeading";

const ButtonWrapper = styled.div`
  width: 253px;
  margin-bottom: 12px;
  ${up("lg")} {
    margin-left: 18px;
    margin-bottom: 0;
  }
`;

const RowToCol = styled.div`
  display: flex;
  flex-direction: column;

  ${up("lg")} {
    flex-direction: row;
  }
`;

export const AllListingsWithCategorySidebar = () => {
  const { publicListings, getPublicListings } = useListing();
  const [conditionFilter, setConditionFilter] = useState<
    CONDITIONS | undefined
  >(undefined);
  const [categoryFilter, setCategoryFilter] = useState<ICategory | undefined>(
    undefined
  );
  const [subCategoryFilter, setSubCategoryFilter] = useState<any>(undefined);

  const { categoryApiName } = useParams();
  const paramCategory = categoryApiName
    ? getCategoryByApiName(categoryApiName)
    : undefined;

  useEffect(() => {
    if (paramCategory) {
      setCategoryFilter(paramCategory);
    }
  }, [setCategoryFilter, paramCategory]);

  useEffect(() => {
    getPublicListings();
  }, [getPublicListings]);

  const conditionFilteredListings = publicListings.filter(
    (listing) =>
      conditionFilter === undefined ||
      listing.attributes.publicData.condition === conditionFilter.toUpperCase()
  );

  const filteredListings = conditionFilteredListings.filter(
    (listing) =>
      categoryFilter === undefined ||
      listing.attributes.publicData.category === categoryFilter.apiName
  );

  const subCategoryFilteredListings = filteredListings.filter(
    (listing) =>
      subCategoryFilter === undefined ||
      listing.attributes.publicData.subCategory === subCategoryFilter.apiName
  );

  return (
    <LayoutBodyFullwidth>
      <LayoutBody>
        <SectionHeading title="All listings">
          <RowToCol>
            <ButtonWrapper>
              <Button
                onClick={() => setConditionFilter(CONDITIONS.NEW)}
                buttonType="button"
                text="NEW ITEMS ONLY"
                type="primary"
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                buttonType="button"
                text="USED ITEMS ONLY"
                type="primary"
                onClick={() => setConditionFilter(CONDITIONS.USED)}
              />
            </ButtonWrapper>
          </RowToCol>
        </SectionHeading>
        <ListingsGridWithSideContent listings={subCategoryFilteredListings}>
          <CategorySidebar
            categoryFilter={categoryFilter}
            subCategoryFilter={subCategoryFilter}
            setCategoryFilter={setCategoryFilter}
            setSubCategoryFilter={setSubCategoryFilter}
          />
        </ListingsGridWithSideContent>
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};
