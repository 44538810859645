import { Link, useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import {
  ALL_CATEGORIES,
  ICategory,
  ISubCategory,
} from "../constants/categories";
import { COLORS } from "../constants/colors";
import { useSearch } from "../hooks/useSearch";

const Wrapper = styled.ul`
  display: none;

  ${up("lg")} {
    display: block;
  }

  font-size: 16px;
  line-height: 30px;
  background-color: #fbf0eb;
  padding: 34px;
`;

const Category = styled.li`
  min-width: 184px;
  color: ${COLORS.NAVY_BLUE};
  text-align: left;
  font-weight: bold;
  margin-bottom: 4px;
`;

const SubCategory = styled.ul`
  margin-left: 24px;
`;

export const ListingSidebar = () => {
  const { category, subCategory, setCategory, setSubCategory } = useSearch();
  const navigate = useNavigate();

  return (
    <Wrapper>
      {ALL_CATEGORIES.map((cat: ICategory) => (
        <Link key={cat.apiName + "Sidebar"} to={"/buy/" + cat.apiName}>
          <Category>
            {cat.name}
            {category === cat && (
              <SubCategory>
                {cat.subCategories?.map((subCat: ISubCategory) => (
                  <li key={subCat.apiName}>{subCat.name}</li>
                ))}
              </SubCategory>
            )}
          </Category>
        </Link>
      ))}
    </Wrapper>
  );
};
