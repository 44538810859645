import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";

const PrivacyPolicyHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf0eb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 180px;
  margin-bottom: 60px;

  ${up("lg")} {
    height: 220px;
  }
`;

const PrivacyPolicyContent = styled.div`
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;

  ${up("lg")} {
    width: 70%;
  }
`;

const PrivacyPolicyText = styled.div`
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  line-height: 22px;
`;

const PrivacyPolicyHeroText = styled.div`
  display: flex;
  width: 80%;
  align-items: center;

  ${up("lg")} {
    width: 70%;
  }
`;

const StyledH1 = styled.h1`
  color: #304669;
  font-size: 40px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

// PAGE LAYOUT EXPORT

export const PrivacyPolicy = () => {
  return (
    <div>
      <PrivacyHeroWrap />
      <LayoutBodyFullwidth>
        <PrivacyContentWrap />
      </LayoutBodyFullwidth>
    </div>
  );
};

const PrivacyHeroWrap = () => {
  return (
    <PrivacyPolicyHero>
      <PrivacyPolicyHeroText>
        <StyledH1>Privacy Policy</StyledH1>
      </PrivacyPolicyHeroText>
    </PrivacyPolicyHero>
  );
};

const PrivacyContentWrap = () => {
  return (
    <PrivacyPolicyContent>
      <PrivacyPolicyText>
        <p
          style={{
            color: "#304669",
            fontSize: "16px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          At bellybutton we don't collect much but we take data privacy
          seriously.
          <br></br>
          <br></br>
          To sign up to bellybutton you will input your; <br></br>- Name,
          <br></br>- Email, <br></br>- Generic suburban location. <br></br>
          We may also collect your profile image if you set one up.
          <br></br>
          <br></br>
          We will collect and store this using best practice to ensure data
          integrity.
          <br></br>
          <br></br>
          The information will be stored on a combination of private owned
          secure servers and secure cloud platforms, and deleted securely after
          a suitable period of time if the data is no longer relevant for the
          purpose for which it has been collected.
          <br></br>
          <br></br>
          We do not share this information with any other parties and at any
          time, you have the right to ask to see, correct or provide additional
          information that we hold about you.
          <br></br>
          <br></br>
          bellybutton also uses google analytics, the purpose of any information
          that we collect or hold is to try and provide you with the best
          service that we possibly can. This includes;<br></br>- Delivery of
          bellybutton services <br></br>- Improving the delivery of bellybutton
          services <br></br>- Delivering personalised advertisements, offerings
          and recommendations <br></br>- Identifying market trends <br></br>-
          Preventing, limiting and investigating abuse of bellybutton<br></br>
          services
          <br></br>
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Happy buying, selling and giving!
          </span>
        </p>
      </PrivacyPolicyText>
    </PrivacyPolicyContent>
  );
};
