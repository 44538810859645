import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";

export const MyAccountHero = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.DARK_ORANGE};
  height: 150px;
  margin-bottom: 60px;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  font-weight: bold;

  ${up("md")} {
    color: ${COLORS.NAVY_BLUE};
    color: ${COLORS.NAVY_BLUE};
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/assets/images/header-image.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 229px;
    margin-bottom: 56px;
  }

  width: 100%;
`;
