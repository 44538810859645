import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { ConfirmPurchaseSection } from "../components/ConfirmPurchaseSection";
import { ContactSellerModal } from "../components/ContactSellerModal";
import { ImageCarousel } from "../components/ImageCarousel";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { ListingSidebar } from "../components/ListingSidebar";
import { OtherListings } from "../components/OtherListings";
import { COLORS } from "../constants/colors";
import { CONDITIONS } from "../constants/conditions";
import { ALL_LOCATIONS } from "../constants/locations";
import { POSTAGE } from "../constants/postage";
import { useAuth } from "../hooks/useAuth";
import { IListing, useListing } from "../hooks/useListing";
import { getListingDateAsStringFromListing } from "../utils/date";
import { getImageURLsByListing } from "../utils/images";
import { formatPriceFromPriceObject } from "../utils/price";
import { getUsernameForListing } from "../utils/users";

export const Listing = (props: { previewMode?: boolean }) => {
  const params = useParams();
  const { id } = params;
  const [confirmPurchase, setConfirmPurchase] = React.useState(false);
  const { isSignedIn } = useAuth();
  const { getListingById, listingById } = useListing();

  useEffect(() => {
    getListingById(id || "");
  }, [id]);

  const onClickBuy = useCallback(() => setConfirmPurchase(true), [isSignedIn]);
  const onClickCancel = () => setConfirmPurchase(false);

  if (!listingById) {
    return <div>Loading...</div>;
  }

  if (!id) {
    return <div>Something went wrong. Please try again. </div>;
  }

  return (
    <LayoutBodyFullwidth>
      <LayoutBody>
        {confirmPurchase ? (
          <ConfirmPurchaseSection
            onCancelPurchase={onClickCancel}
            listing={listingById}
          />
        ) : null}
        <ListingByIdWithDetails
          onClickBuy={onClickBuy}
          onClickCancel={onClickCancel}
          listing={listingById}
          hideMoreButtons={confirmPurchase}
        />
        <OrangeDelimiter />
        {!confirmPurchase && <OtherListings ignoreId={id} />}
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};

interface IListingByIdWithDetailsProps {
  listing: IListing | null;
  showAuthor?: boolean;
  hideMoreButtons: boolean;
  onClickBuy?: () => void;
  onClickCancel?: () => void;
}

export const ListingByIdWithDetails = (props: IListingByIdWithDetailsProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const toggleDialog = () => setShowDialog((previous) => !previous);
  const { listing, hideMoreButtons, onClickBuy } = props;
  const { allMetadata } = useListing();
  const { user } = useAuth();
  const MyUsername = user?.attributes?.profile?.displayName;
  const navigate = useNavigate();

  if (!listing) {
    // TODO replace with a prettier loading screen.
    return <div>Loading...</div>;
  }

  const { title, description, price } = listing.attributes;
  const { location, condition, postage } = listing.attributes.publicData;
  const imageUrls = getImageURLsByListing(listing, allMetadata);
  const username = getUsernameForListing(listing, allMetadata);
  const authorId = listing?.relationships?.author?.data?.id?.uuid;

  const isMyListing = MyUsername === username;
  const relativeDate = getListingDateAsStringFromListing(listing);

  const onClickWithdraw = () => {
    navigate("/withdrawlisting/" + listing.id.uuid);
  };

  const onClickEdit = () => {
    navigate("/edit-listing/" + listing.id.uuid);
  };

  const onClickViewAuthor = () => {
    navigate("/account/" + authorId);
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Buy {title}</title>
      </Helmet>
      {!hideMoreButtons && <ListingSidebar />}
      <ContactSellerModal
        showDialog={showDialog}
        toggle={toggleDialog}
        senderId={user?.id?.uuid ?? undefined}
        recipientId={authorId}
        listingName={listing.attributes.title}
      />
      <MainContentArea>
        <DetailsArea>
          <Title>{title}</Title>
          <UsernameLocationArea onClick={onClickViewAuthor}>
            <PersonIcon />

            <UsernameText>
              {isMyListing ? "Listed by you" : username}
            </UsernameText>

            <WorldIcon />
            <IconText>
              {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
            </IconText>
          </UsernameLocationArea>
          <Date>
            <i>Listed {relativeDate}</i>
          </Date>
          <Delimiter />
          <PriceRow>
            <Price>{formatPriceFromPriceObject(price)}</Price>
            <BuyButtonWrapper>
              {!hideMoreButtons && !isMyListing && (
                <Button
                  buttonType="button"
                  text="BUY THIS ITEM"
                  type="bluebg"
                  onClick={onClickBuy}
                />
              )}
            </BuyButtonWrapper>
            {isMyListing && !hideMoreButtons && (
              <BuyButtonsButtonsWrapper>
                <BuyButtonWrapper style={{ marginBottom: "12px" }}>
                  <Button
                    buttonType="button"
                    text="EDIT LISTING"
                    type="bluebg"
                    onClick={onClickEdit}
                  />
                </BuyButtonWrapper>
                <BuyButtonWrapper>
                  <Button
                    buttonType="button"
                    text="WITHDRAW LISTING"
                    type="blueborder"
                    onClick={onClickWithdraw}
                  />
                </BuyButtonWrapper>
              </BuyButtonsButtonsWrapper>
            )}
          </PriceRow>
          <FilterDetailsArea>
            <h3>Condition</h3>
            <p>{CONDITIONS[condition as keyof typeof CONDITIONS]}</p>
            <h3>Postage</h3>
            <p>{POSTAGE[postage as keyof typeof POSTAGE]}</p>
            <h3>Location</h3>
            <p>{ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}</p>
          </FilterDetailsArea>
          <DescriptionHeading>Description</DescriptionHeading>
          <Description>{description}</Description>
        </DetailsArea>
        <ImageArea>
          <ImageCarousel imageUrls={imageUrls} />
          {!hideMoreButtons && !isMyListing && (
            <MoreButtonRow>
              <MoreButtonCol>
                <Button
                  buttonType="button"
                  type="secondary"
                  text="MORE FROM THIS SELLER"
                  onClick={onClickViewAuthor}
                />
                <Button
                  buttonType="button"
                  type="secondary"
                  text="ASK A QUESTION"
                  onClick={toggleDialog}
                />
              </MoreButtonCol>
            </MoreButtonRow>
          )}
        </ImageArea>
      </MainContentArea>
    </Wrapper>
  );
};

const PersonIcon = () => (
  <img src="/assets/images/profile.svg" alt="Profle Icon" />
);
const WorldIcon = () => <img src="/assets/images/globe.svg" alt="Globe Icon" />;

const Wrapper = styled.div`
  display: flex;
  max-width: 100vw;
  justify-content: center;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 42px;
`;

const MainContentArea = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  padding-left: 24px;
  padding-right: 24px;

  ${up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 72px;
    grid-column-gap: 58px;
  }

  :first-child {
    margin-left: 0;
  }
`;

const DetailsArea = styled.div`
  display: flex;
  flex-direction: column;
`; // Left panel
const ImageArea = styled.div``; // Right panel

const Title = styled.h1`
  font-size: 2rem;
  ${up("md")} {
    font-size: 40px;
    line-height: 45px;
  }

  font-weight: bold;
  margin-bottom: 14px;
  text-align: left;
`;
const UsernameLocationArea = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  transition: all 0.2s ease-in-out;
`;
const Date = styled.h3`
  font-size: 10px;
  line-height: 11px;
  text-align: left;
  margin-bottom: 32px;
`;

const Delimiter = styled.div`
  height: 1px;
  background-color: ${COLORS.NAVY_BLUE};
  width: 95%;
  margin-bottom: 32px;
`;
const OrangeDelimiter = styled.div`
  height: 1px;
  background-color: ${COLORS.DARK_ORANGE};
  width: 100%;
  margin-bottom: 42px;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  ${up("md")} {
    flex-direction: row;
  }
`;
const BuyButtonWrapper = styled.div`
  width: 190px;
`;
const Price = styled.h3`
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  text-align: left;
`;

const FilterDetailsArea = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  align-items: center;
  margin-bottom: 48px;
  h3 {
    font-size: 20px;
    line-height: 36px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }
`;

const DescriptionHeading = styled.h3`
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 14px;
  font-weight: bold;
  text-align: left;
`;
const Description = styled.p`
  white-space: pre-line;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
`;

const MoreButtonRow = styled.div`
  display: flex;
  justify-content: center;
  ${up("md")} {
    justify-content: flex-end;
  }
`;
const MoreButtonCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 190px;

  button {
    font-size: 12px !important;
    margin-bottom: 15px;
  }
`;

const IconText = styled.span`
  margin-left: 9px;
  white-space: nowrap;
  margin-right: 100px;
`;
const UsernameText = styled.span`
  margin-left: 9px;
  white-space: nowrap;
  margin-right: 50px;

  ${up("sm")} {
    margin-right: 100px;
  }

  :hover {
    cursor: pointer;
    scale: 1.1;
    color: ${COLORS.DARK_ORANGE};
  }
`;

const BuyButtonsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
