import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Ad } from "../components/Ad";
import { Button } from "../components/Button";
import { H1, H4 } from "../components/Header";
import { LayoutBodyFullwidth } from "../components/Layout";
import { Login } from "../components/Login";

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const LoginLogo = styled.img`
  width: 100px;
  height: 100px;

  ${up("lg")} {
    width: 119px;
    height: 119px;
  }
`;

// const AdvertisingBannerLogin = styled.img`
//   width: 1200px;
//   height: 160px;
//   margin-top: 100px;
//   @media (max-width: 424px) {
//     display: none;
//   }
// `;

// const AvertisingBannerLoginMobile = styled.img`
//   height: 160px;
//   max-width: 90%;
//   @media (min-width: 425px) and (max-width: 2565px) {
//     display: none;
//   }
//   @media (max-width: 424px) {
//     display: flex;
//   }
// `;

const ButtonWrapper = styled.div`
  width: 40%;

  ${up("lg")} {
    width: 10%;
    margin-bottom: 30px;
  }
`;

const LoginPageWrap = styled.div`
  margin-bottom: 60px;
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 24px;
  font-weight: normal;
  margin-top: 40px;
  width: 80%;
  ${up("lg")} {
    font-size: 30px;
    width: 100%;
  }
`;

const StyledH4 = styled.h4`
  color: #8a0000;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 10px;
`;

// PAGE LAYOUT EXPORT

export const LoginPage = () => {
  return (
    <LoginPageWrap>
      <LoginHeroWrap />
      <LayoutBodyFullwidth>
        <Login />
        <div style={{ marginTop: "80px" }}>
          <Ad
            href="https://kindello.com/"
            mobileImg="/assets/images/kindello-mob.png"
            desktopImg="/assets/images/LOGINKindelloDesktop2.png"
            tabletImg="/assets/images/LOGINKindelloTablet.svg"
          />
        </div>
      </LayoutBodyFullwidth>
    </LoginPageWrap>
  );
};

const LoginHeroWrap = () => {
  const navigate = useNavigate();
  const onClickRegister = () => {
    navigate("/sellregister");
  };
  return (
    <HeroWrapper>
      <LoginLogo
        src="/assets/images/bellybutton-hearticon.svg"
        alt="Bellybutton Blue Heart Icon"
      />
      <StyledH1>
        Login to your account to get buying and <br></br> selling!
      </StyledH1>
      <StyledH4>Don't have an account yet?</StyledH4>
      <ButtonWrapper>
        <Button
          onClick={onClickRegister}
          buttonType="button"
          type="primary"
          text="REGISTER"
        />
      </ButtonWrapper>
    </HeroWrapper>
  );
};

// const AdvertisingBannerWrap = () => {
//   return (
//     <div>
//       <a href="https://kindello.com/">
//         <AdvertisingBannerLogin src="/assets/images/LOGINKindelloDesktop.svg" />
//         <AvertisingBannerLoginMobile src="/assets/images/LOGINKindelloMobile.svg" />
//       </a>
//     </div>
//   );
// };
