import { Link } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { useAuth } from "../hooks/useAuth";
import { StyledLink } from "./Link";
import { MobileNav } from "./MobileNav";

{
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
  />;
}

const DesktopWrapper = styled.nav`
  display: none;
  z-index: 2000;

  ${up("lg")} {
    display: flex;
    height: 90px;
    width: 100%;
    padding-left: 88px;
    padding-right: 80px;
    justify-content: space-between;
    max-height: 90px;
  }
`;

const NavLeftContent = styled.div`
  display: flex;
  align-items: center;
`;
const NavRightContent = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  transition: all 0.3s ease-in-out;
  width: 202px;
  height: 44px;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    width: 140px;
    align-items: center;
  }

  @media (max-width: 376px) {
    width: 140px;
  }
`;

export const Navigation = () => {
  const { isSignedIn } = useAuth();

  return (
    <>
      <MobileNav />
      <DesktopWrapper>
        <NavLeftContent>
          <Link to={"/"}>
            <LogoImage src="/assets/images/bellybutton-header.svg" alt="Bellybutton Site Logo"  />
          </Link>
        </NavLeftContent>
        <NavRightContent>
          <StyledLink text="Buy" type="tertiary" href="buy" />
          <StyledLink text="Sell" type="tertiary" href="sell" />
          <StyledLink text="Apps" type="tertiary" href="parentingapps" />
          <StyledLink text="FAQ" type="tertiary" href="faq" />
          {!isSignedIn && (
            <>
              <StyledLink
                text="REGISTER"
                type="secondary"
                href="sellregister"
              />
              <StyledLink text="LOGIN" type="primary" href="login" />
            </>
          )}

          {isSignedIn && (
            <>
              <StyledLink
                text="MY ACCOUNT"
                type="secondary"
                href="/myaccount"
              />
              <StyledLink text="LOGOUT" type="primary" href="/logout" />
            </>
          )}
        </NavRightContent>
      </DesktopWrapper>
    </>
  );
};
